import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiUrl;

  constructor( private http: HttpClient, private router: Router) { }
  login(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/login_user`, data);
  }

  register(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/register_user`, data)
  }

  verify(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/verify_otp`, data);
  }

  resend_otp(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/resend_otp`, data);
  }

  forgot_password(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/forgot_password`, data)
  }

  reset_password(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/reset_password`, data)
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  
  public getAuthToken(): string {
    return localStorage.getItem('auth');
  }
  
  public isAuthenticated(): boolean {
    if (localStorage.getItem('auth') != '') {
      return true;
    } else {
      return false;
    }
    // return moment().isBefore(this.getExpiration());
  }

}
