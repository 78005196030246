import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor} from './services/auth.interceptor';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, BrowserAnimationsModule, AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAmmo5iqyWMEROq8eBQy_s7zjxF_qJ2mT0',
    libraries: ['places']
  }), NgxSpinnerModule],
  providers: [
    Camera,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    
    { provide: HTTP_INTERCEPTORS,useClass: AuthInterceptor,multi: true
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
