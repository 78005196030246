import { Component } from '@angular/core';
import { Platform} from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private platform: Platform, public _router: Router, public _location: Location, private auth:AuthService, private api:ApiService, private spinner: NgxSpinnerService) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
    });
  }

  logout() {
    this.auth.logout();
  }

  ngOnInit() {
    this.checkAuth();
  }

  checkAuth() {
    if (localStorage.getItem('auth')) {
      this.checkSubscription();
    } else {
      this._router.navigate(['welcome']);
    }
  }

  checkSubscription() {
    this.spinner.show();
    this.api.check_subscription().subscribe((rs) => {
      //console.log("========>", rs);
      if (rs.message == "Unauthenticated") {
        this.logout();
      }
      // if (rs.result) {
        if (rs.result && rs.result.id) {
          this._router.navigate(['tabs/tab2/1']);
        } else {
          this._router.navigate(['subscription/2']);
        }
      // }
      
    });
  }

  ngOnDestroy() {
    
  }
  // initializeApp() {
  //   document.addEventListener("deviceready", () => {
  //       setTimeout(()=>{
  //        SplashScreen.hide({
  //          fadeOutDuration: 1000
  //        });
  //      }, 5000)
  //   })
  // }

  // initializeApp() {
    // this.platform.ready().then(() => {
    //   setTimeout(() => {
    //     this.splashScreen.hide();
    //   }, 1000);
    // }
  // }

  refresh(): void {
    this._router.navigateByUrl("/refresh", {skipLocationChange: true}).then(() => {
      console.log(decodeURI(this._location.path()));
      this._router.navigate([decodeURI(this._location.path())])
    });
  }

}
