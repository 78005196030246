import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { IonicToastService } from './ionic-toast.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private ionicToastService: IonicToastService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authService.getAuthToken();

    if (token) {
      request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
     });
    }

    return next.handle(request);
  }

  // Error Toaster
  error(err) {
    // Show Error
    this.ionicToastService.showToast('Error: '+err.error.message || 'Error: '+err.error.msg);
  }
}