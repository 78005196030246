import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
    private url: string;
  
    constructor(private auth: AuthService,
                private router: Router) {}
  
  private handleAuthstate(): boolean {
    if ( this.isLoginOrRegister() ) {
      this.router.navigate(['/subscription/1']);
      return false;
    }
  
    return true;
  }
  
  private handleNotAuthstate(): boolean {
    if ( this.isLoginOrRegister() ) {
      return true;
    }
  
    this.router.navigate(['/login']);
    return false;
  }
  
  private isLoginOrRegister(): boolean {
    if ( this.url.includes('login') || this.url.includes('register') || this.url.includes('welcome')) {
      return true;
    }
  
    return false;
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.url = state.url;
    // console.log("Auth--------------------------->",localStorage.getItem('auth'));
    // console.log("--------------------------->", this.url);
    // if ( this.auth.isAuthenticated() ) {
    //   return this.handleAuthstate();
    // }
    if (localStorage.getItem('auth')) {
        // console.log("Token--------------------------->", this.auth.isAuthenticated);
        return this.handleAuthstate();
    }
    return this.handleNotAuthstate();
  }
  
  }