import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class IonicToastService {
  private myToast: any;
  constructor(public toast: ToastController) { }
  showToast(message) {
    this.myToast = this.toast.create({
      message: message,
      duration: 2000,
      // header: 'Welcome!',
      // message: 'John!',
      position: 'bottom',
      cssClass: 'toast-custom-class',
      color: 'warning',
      // animated: true
    }).then((toastData) => {
      // console.log(toastData);
      toastData.present();
    });
  }
  HideToast() {
    this.myToast = this.toast.dismiss();
  }
}
