import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl = environment.apiUrl;
  constructor( private http: HttpClient, private router: Router) { }

  getPofile() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/get_profile`);
  }

  updateProfile(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/update_profile`, data);
  }
  addAddress(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/add_address`, data);
  }

  getAllStore() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/store_list`);
  }

  getAllCategoryByStoreId(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/store_by_id`, data);
  }

  getAllOrderList() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/order_list`);
  }
  getOrderDetail(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/order_list_detail`, data);
  }
  getOrderById(id){
    return this.http.get<any>(`${this.apiUrl}/api/v1/getorderById?order_id=${id}`);
  }
  
  getStoreName(id) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/store_id_by_store_name?store_id=${id}`);
  }
  getCategoryName(id) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/cat_id_by_category_name?category_id=${id}`);
  }
  getSubCategoryName(id) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/sub_cat_id_by_sub_category_name?sub_category_id=${id}`);
  }
  getWeightCategoryList() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/get_weight_cat_list`);
  }
  addToCart(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/add_to_cart`, data);
  }
  updateQuantity(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/cart_update`, data);
  }
  addOrder(data) {
    
    return this.http.post<any>(`${this.apiUrl}/api/v1/add_orders`, data);
  }
  getCartList() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/cart_list`);
  }
  getAllSubCategoryByStoreId(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/store_by_cat_id`, data);
  }
  getAllProductByStoreId(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/store_by_cat_subcat_id`, data);
  }
  getAllProductByStoreCateId(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/product_by_store_category`, data);
  }
  getProductDetailById(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/product_detail`, data);
  }

  change_password(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/change_password`, data);
  }

  search_store(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/search_store`, data);
  }
  search_category(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/search_category`, data);
  }
  search_sub_category(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/search_sub_category`, data);
  }
  search_product(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/search_product`, data);
  }

  get_all_category_by_id(id) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/category_list`);
  }

  get_all_notification() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/get_all_notification`);
  }

  store_card(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/store-card`, data);
  }

  make_payment(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/make-payment`, data);
  }
  make_payment_tip(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/make-payment-tip`, data);
  }

  get_saved_cards() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/get-cards`);
  }

  read_notifications(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/read_notification`, data);
  }

  get_all_subscriptions() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/subscriptions`)
  }

  buy_subscription(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/buy_subscription`, data)
  }

  filter_product(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/filter_store_category`, data);
  }

  clear_noti(key) {
    if (key) {
      return this.http.post<any>(`${this.apiUrl}/api/v1/clear_notification?notification_for=${key}`, {});
    }
    return this.http.post<any>(`${this.apiUrl}/api/v1/clear_notification`, {});
    
  }

  check_subscription() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/check_subscription`);
  }

  cancel_subscription(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/cancel_subscription`, data);
  }

  get_all_categories() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/category_list`);
  }

  get_filter_list(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/filter_store_category`, data);
  }

  filter_list(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/filter_store_category`, data);
  }

  cancel_order(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/cancel_orders`, data);
  }
  apply_coupon(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/apply_coupon`, data);
  }
  remove_card(data) {
    return this.http.post<any>(`${this.apiUrl}/api/v1/remove-card`, data);
  }
  get_categories(storeIds) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/category_list_by_store_id?store_id=${storeIds}`);
  }
  get_all_states() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/state_list`);
  }
  getChatMessage(order_id){
    return this.http.get<any>(`${this.apiUrl}/api/v1/get_message?order_id=${order_id}`)
  }
  sendChatMessage(data){
    return this.http.post<any>(`${this.apiUrl}/api/v1/send_message`,data)
  }
   getDepartments(storeId) {
    return this.http.get<any>(`${this.apiUrl}/api/v1/get_department?store_id=${storeId}`);
  }
  delete_account() {
    return this.http.get<any>(`${this.apiUrl}/api/v1/delete_my_account`);
  }


}
